<template>
	<photo img="/img/e9/p1/21" :points="points"/>
</template>

<script>
	import photo from '@/components/photo'

	export default {
		name: 'Prefecture',
		components: {
			photo
		},
		data (){
			return {
				points: [
                    {
						link: 'E9.P1.Prefecture',
						name: 'Posgrado',
						left: 70.5, //'2200px',
						top: 40.5, //'320px',
						width: 4.5, //'150px',
						height: 20 //'180px'
					},
                    {
						link: 'E9.PB.Services',
						name: 'Servicios',
						left: -0.9, //'-20px',
						top: 53, //'450px',
						width: 10, //'200px',
						height: 27 //'180px'
					},
                    {
						link: 'E9.P2.Direction',
						name: 'Planta 2',
						left: -13, //'2900px',
						top: 38, //'200px',
						width: 12, //'175px',
						height: 38 //'160px'
					},
				]
			}
		}
	}
</script>
